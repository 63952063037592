// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.card-content{
  padding-bottom: 100px !important;
}

.historical {
  min-height: 255px;
}

.card {
  min-height: 300px;
}


@media all and (min-width:0px) and (max-width: 568px) {
  .card-content{
    padding-bottom: 50px !important;
  }

  .card {
    min-height: 25em;
  }
}

span.badge {
  color: #7ff7ff;
  font-size: 2rem;
}

#home_buscar span{
  padding-right: 4em;
}

#home_buscar label{
  padding-right: 1em;
}
